<template>
    <div class="row mt-5">
        <div class="col-12">
            <footer class="footer">
                <div class="container-fluid">
                    <div class="row">
                        <div class="box col-11">
                            <div class="col-8 col-sm-6 ps-md-5">
                                <ul>
                                    <li>
                                        <router-link
                                            to="/" class="a hover-decoration">
                                            <span @click="goHomePage">Bosh sahifa</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            to="/course-free" class="a hover-decoration">
                                            <span @click="goToFreeCourse">Bepul kurslar</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            to="/course" class="a hover-decoration">
                                            <span @click="goToCourse">Junior Full Stack Web Developer kursi</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            to="/course-middle" class="a hover-decoration">
                                            <span @click="goToMiddleCourse">Middle Full Stack Web Developer kursi</span>
                                        </router-link>
                                    </li>
                                    <li style="color: #FFFFFF">
                                        Biz bilan aloqa: <br/>
                                        <i class="bi bi-telephone mr-1"></i>
                                        <a
                                            class="hover-decoration mb-4 mt-4"
                                            :href="formatPhoneNumber('+998901302050')"
                                        >
                                            +998 90 130-50-20
                                        </a> <br />
                                        <i class="bi bi-telegram mr-1"></i>
                                        <a
                                            class="hover-decoration"
                                            href="https://t.me/kadirovDevAdmin"
                                            target="_blank"
                                        >
                                            @kadirovDevAdmin
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://apps.kadirov.group/"
                                            class="project-order"
                                            target="_blank"
                                        >
                                            <strong>Dastur buyurtma berish</strong>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-4 col-sm-6">
                                <ul :class="isAuthenticated ? 'margin float-end pe-md-5' : 'margin float-end mt-n4 pe-md-5'">
                                    <li>
                                        <router-link
                                            to="/cabinet"
                                            v-if="isAuthenticated"
                                            class="a hover-decoration"
                                        >
                                            <b @click="goToCabinet">Kabinet</b>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            to="/login"
                                            v-if="!isAuthenticated"
                                            class="a hover-decoration"
                                        >
                                            Kirish
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            to="/"
                                            class="logout hover-decoration"
                                            v-if="isAuthenticated"
                                        >
                                            <span @click="logout">Chiqish</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            to="/registration"
                                            v-if="!isAuthenticated"
                                            class="a hover-decoration"
                                        >
                                            <span @click="goToRegister">Ro’yxatdan o’tish</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            to="/public-offer"
                                            v-if="isAuthenticated"
                                            class="a hover-decoration"
                                        >
                                            <b @click="goToOffer">Ommaviy oferta</b>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {Auth} from "@/services/auth";

export default {
    name: "Footer",
    computed: {
        ...mapGetters(['getToken', 'getAccessToken', 'isAuthorized']),
        isAuthenticated() {
            return this.isAuthorized
        }
    },
    methods: {
        ...mapActions([]),
        logout() {
            Auth.makeLogout()
                .finally(() => {
                    location.reload()
                })
        },
        formatPhoneNumber(number) {
            return `tel:${number}`
        },
        goHomePage() {
            window.scrollTo(0, 0);
        },
        goToFreeCourse() {
            window.scrollTo(0, 0);
        },
        goToCourse() {
            window.scrollTo(0, 0);
        },
        goToMiddleCourse() {
            window.scrollTo(0, 0);
        },
        goToCabinet() {
            window.scrollTo(0, 0);
        },
        goToRegister() {
            window.scrollTo(0, 0);
        },
        goToOffer() {
            window.scrollTo(0, 0);
        }
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&family=Fuzzy+Bubbles&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Comfortaa', cursive;
    box-sizing: border-box;
    animation: fadeInUp;
    animation-duration: 1s;
}

.container-fluid {
    padding-left: 0 !important;
}

.footer {
    background-color: #4A312C;
    display: block;
    padding: 70px 10px;
    margin: auto;
}

.box {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
}

ul {
    list-style: none;
}

li {
    margin-bottom: 20px;
}

a {
    text-decoration: none;
}

.hover-decoration {
    text-decoration: none !important;
    display: inline-block !important;
    position: relative !important;
    color: #FFFFFF !important;
}

.project-order {
    margin: auto;
    color: #03ab18;
    font-size: 20px;
}

.project-order:hover {
    color: #06D120;
}

.a {
    text-decoration: none;
    font-size: 18px;
    color: #FFFFFF;
    font-style: normal;
    display: inline-block;
    cursor: pointer;
}

.logout {
    color: #FFFFFF;
    font-size: 18px;
    font-style: normal;
    cursor: pointer;
    font-weight: 700;
}

.logout:hover {
    transition: all 0.5s;
    text-decoration: underline;
    color: #6F42c1;
}

.margin {
    padding-top: 3px;
}

.project-order {
    text-decoration: none !important;
    display: inline-block !important;
    position: relative !important;
    color: #06D120 !important;
}

.project-order:after {
    content: '' !important;
    position: absolute !important;
    width: 100% !important;
    transform: scaleX(0) !important;
    height: 2px !important;
    bottom: 0 !important;
    left: 0 !important;
    background-color: #06D120 !important;
    transform-origin: bottom center !important;
    transition: transform 0.15s ease-out !important;
}

.project-order:hover:after {
    transform: scaleX(1) !important;
    transform-origin: bottom center !important;
}
</style>
