import apiClient from "@/plugins/vuex/axios";

const SERVICE_ENDPOINT = 'request_for_calls'

export default {
    actions: {
        fetchRequestForCalls(context, page) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?itemsPerPage=20&order[id]=desc&page=` + page)
                    .then((response) => {
                        let requestForCalls = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateRequestForCalls', requestForCalls)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        searchRequestForCalls(context, searchItem) {
            let url = "request_for_calls?itemsPerPage=20&order[id]=desc&page=" + searchItem.page;

            if (searchItem.id) {
                url = url + '&id=' + searchItem.id
            }

            if (searchItem.fullName) {
                url = url + '&fullName=' + searchItem.fullName
            }

            if (searchItem.telegramUsername) {
                url = url + '&telegramUsername=' + searchItem.telegramUsername
            }

            if (searchItem.phone) {
                url = url + '&phone=' + searchItem.phone
            }

            if (searchItem.message) {
                url = url + '&message=' + searchItem.message
            }

            if (searchItem.createdAt) {
                url = url + '&createdAt=' + searchItem.createdAt
            }

            if (searchItem.isResponseSent) {
                url = url + '&isResponseSent=' + searchItem.isResponseSent
            }

            return new Promise((resolve, reject) => {
                apiClient.get(url)
                    .then((response) => {
                        let data = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateRequestForCalls', data)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        changeRequestForCallResponseSent(context, itemId) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}/mark_as_response_sent?id=${itemId}`)
                    .then((response) => {
                        console.log(response)
                        resolve()
                    })
                    .catch(() => {
                        console.log('changeRequestForCallResponseSent da xato')
                        reject()
                    })
            })
        },
        fetchAllNewRequestForCalls(context) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?pagination=false&hasSeen=false`)
                    .then((response) => {
                        let requestForCalls = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateAllNewRequestForCalls', requestForCalls)
                        resolve()
                    })
                    .catch(() => {
                        console.log('get comments da xato')
                        reject()
                    })
            })
        },
        makeRequestForCallHasSeen(context, ids) {
            console.log(ids)
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}/make_seen?pagination=false&ids=${ids}`)
                    .then((response) => {
                        console.log(response)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchApprove da xato')
                        reject()
                    })
            })
        },
        deleteOneRequestForCall(context, id) {
            return new Promise((resolve, reject) => {
                apiClient.delete(`${SERVICE_ENDPOINT}/` + id)
                    .then((response) => {
                        console.log('delete ',response)
                        context.commit('updateAllComments', response)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchComment da xato')
                        reject()
                    })
            })
        },
    },
    mutations: {
        updateRequestForCalls(state, data) {
            console.log(data)
            state.requestForCalls = data
        },
        updateAllNewRequestForCalls(state, data) {
            console.log(data)
            state.allNewRequestForCalls = data
        },
    },
    state: {
        requestForCalls: {
            models: [],
            totalItems: 0,
        },
        allNewRequestForCalls: {
            models: [],
            totalItems: 0,
        },
    },
    getters: {
        getRequestListTotalCount(state) {
            return state.requestForCalls.totalItems
        },
        getRequestList(state) {
            return state.requestForCalls.models
        },
        getAllNewRequestForCalls(state) {
            return state.allNewRequestForCalls.totalItems
        },
        getAllNewRequestForCallIds(state) {
            return state.allNewRequestForCalls.models.map(item => item.id).join(',')
        },
    }
}
