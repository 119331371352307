import $router from "../../plugins/router"
import apiClient from "@/plugins/vuex/axios";
import localToken from "@/plugins/local/localToken";

const SERVICE_ENDPOINT = `${process.env.VUE_APP_API_URL}/api/users`

export class Auth {
    static async makeLogin(email, password) {
        try {
            const response = await apiClient.post(`${SERVICE_ENDPOINT}/auth`, {
                email,
                password,
            })
            const {status} = response
            if (status === 200) {
                console.log('ok')
            }
            return true
        } catch (error) {
            if (error.response.status === 401) {
                console.log(error)
            }
            // throw new error
        }
    }

    static async makeRegistration(email,
                                  password,
                                  givenName,
                                  familyName,
                                  birthday,
                                  phone,
                                  gender
    ) {
        try {
            const response = await apiClient.post(`${SERVICE_ENDPOINT}`, {
                email,
                password,
                givenName,
                familyName,
                birthday,
                phone,
                gender
            })
            const {status} = response
            if (status === 200) {
                const res = await this.makeLogin(email, password)
                return res === true
            }
            return false
        } catch (error) {
            console.log(error)
            throw new error
        }
    }

    static async makeLogout() {
        try {
            localToken.clearToken()
            await $router.push('/')
        } catch (error) {
            console.log(error)
            throw new error
        }
    }
}
