const LOCAL_STORAGE_TOKEN = 'token';

export class TokenStorage {

    static isAuthenticated() {
        return this.getToken() !== null;
    }

    static storeToken(token) {
        localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
    }

    static getToken() {
        return localStorage.getItem(LOCAL_STORAGE_TOKEN);
    }
}
