import axios from "axios";
import store from "./store";
import router from "../router";

const authUrl = `${process.env.VUE_APP_API_URL}/api/users/auth`

const apiClient = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}/api/`,
    headers: {'Content-Type': 'application/json'},
})

apiClient.interceptors.request.use((config) => {
    if (config.url !== authUrl)

        config.headers.common['Authorization'] = "bearer " + store.getters.getAccessToken

    return config
})

apiClient.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response === undefined) {
            return Promise.reject('connectionRefused');
        }

        if (
            error.response.status === 401 &&
            this.$route.name !== 'home' &&
            this.$route.name !== 'home-page' &&
            this.$route.name !== 'junior-course' &&
            this.$route.name !== 'middle-course' &&
            this.$route.name !== 'free-course' &&
            (error.config.url !== '/api/users/auth' || error.config.url !== '/api/users/auth/refreshToken')
        ) {
            store.dispatch('fetchRefreshToken', {refreshToken: localStorage.getItem('refreshToken')})
                .catch(() => {
                    store.dispatch('clearTokens')
                    router.push('/login')
                })
        }

        return Promise.reject(error);
    });

export default apiClient
