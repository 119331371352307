import apiClient from "@/plugins/vuex/axios";

const SERVICE_ENDPOINT = 'course_types'

export default {
    actions: {
        pushCourseType(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`${SERVICE_ENDPOINT}`, data)
                    .then((response) => {
                        console.log(response)
                        context.commit('updateNewCourseType', response.data.newCourseType)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        fetchCourseTypes(context) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}`)
                    .then((response) => {
                        let courseTypes = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateCourseTypes', courseTypes)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchCourseTypes da xato')
                        reject()
                    })
            })
        },
        fetchCoursePagination(context, page) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?page=` + page + '&order[id]=desc')
                    .then((response) => {
                        let courseTypes = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateCourseTypes', courseTypes)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchCourseTypes da xato')
                        reject()
                    })
            })
        },
        fetchCourseType(context, courseTypeId) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}/` + courseTypeId)
                    .then((response) => {
                        let courseType = response.data

                        console.log(courseType, 'T')
                        context.commit('updateCourseType', courseType)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchCourseTypes da xato')
                        reject()
                    })
            })
        },
        putCourseType(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.put(`${SERVICE_ENDPOINT}/${data.id}`, data)
                    .then((response) => {
                        context.commit('updateCourseType', response.data)
                        resolve()
                    })
                    .catch(() => {
                        console.log('post course da xato')
                        reject()
                    })
            })
        },
        deleteCourseType(context, courseTypeId) {
            return new Promise((resolve, reject) => {
                apiClient.delete(`${SERVICE_ENDPOINT}/` + courseTypeId)
                    .then((response) => {
                        console.log(response)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        searchCourseType(context, searchItem) {
            let url = "course_types?page=" + searchItem.page;

            if (searchItem.id) {
                url = url + '&id=' + searchItem.id
            }

            if (searchItem.name) {
                url = url + '&name=' + searchItem.name
            }

            if (searchItem.duration) {
                url = url + '&duration=' + searchItem.duration
            }

            return new Promise((resolve, reject) => {
                apiClient.get(url)
                    .then((response) => {
                        let data = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateSearchCourseType', data)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
    },
    mutations: {
        updateSearchCourseType(state, data) {
            state.courseTypes = data
        },
        updateCourseTypes(state, data) {
            state.courseTypes = data
        },
        updateCourseType(state, data) {
            state.courseType = data
        },
        updateNewCourseType(state, newCourseType) {
            state.newCourseType = newCourseType
        }
    },
    state: {
        addCourse: {
            name: '',
            price: '',
        },
        courseTypes: {
            models: [],
            totalItems: 0,
        },
        courseType: null,
        newCourseType: {
            name: '',
            price: '',
            category: '',
            duration: null
        }
    },
    getters: {
        getAddCourseType(state) {
            return state.addCourse
        },
        getSearchSearchUser(state) {
            return state.courseTypes.models
        },
        getCourseTypes(state) {
            return state.courseTypes.models
        },
        getCourseTypesTotal(state) {
            return state.courseTypes.totalItems
        },
        getCourseType(state) {
            return state.courseType
        },
    }
}