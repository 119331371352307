import apiClient from "@/plugins/vuex/axios";

const SERVICE_ENDPOINT = 'media_objects'

export default {
    actions: {
        pushImage(context, data) {
            return new Promise((resolve, reject) => {
            apiClient.post(`${SERVICE_ENDPOINT}`, data)
                .then((response) => {
                    context.commit('updateMediaObject', response.data)
                    resolve()
                })
                .catch(() => {
                    console.log('FAILURE!!');
                    reject()
                });
            })
        },

        mediaFetch(context) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}`)
                    .then((response) => {
                        let courseTypes = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateMediaObjects', courseTypes)
                        resolve()
                    })
                    .catch( () => {
                        console.log('mediaFetch da xato')
                        reject()
                    })
            })
        }
    },

    mutations: {
        updateMediaObjects(state, model) {
            state.mediaObjects = model
        },
        updateMediaObject(state, model) {
            state.mediaObject = model
        },
    },

    state: {
        mediaObjects: null,
        mediaObject: null
    },
    getters: {
        getImages(state) {
            return state.mediaObjects
        },
        getImage(state) {
            return state.mediaObject
        },
    }

}