import Vue from "vue"
import Vuex from "vuex"
import user from "./user";
import courseType from "@/plugins/vuex/courseType";
import course from "@/plugins/vuex/course";
import boughtCourse from "@/plugins/vuex/boughtCourse";
import comment from "@/plugins/vuex/comment";
import month from "@/plugins/vuex/month";
import {TokenStorage} from "@/services/toke-storage";
import mediaObject from "@/plugins/vuex/mediaObject";
import promoCode from "@/plugins/vuex/promoCode";
import courseStudents from "@/plugins/vuex/courseStudents";
import module from "@/plugins/vuex/module";
import category from "@/plugins/vuex/category";
import commentLike from "@/plugins/vuex/commentLike";
import requestForCall from "@/plugins/vuex/requestForCall";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        courseType,
        course,
        boughtCourse,
        comment,
        month,
        TokenStorage,
        mediaObject,
        promoCode,
        courseStudents,
        module,
        category,
        commentLike,
        requestForCall
    }
})