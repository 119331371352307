import apiClient from "@/plugins/vuex/axios";

const SERVICE_ENDPOINT = 'users'

export default {
    actions: {
        fetchPushUser(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`${SERVICE_ENDPOINT}`, data)
                    .then((response) => {
                        context.commit('updatePushUsers', response.data.user)
                        resolve()
                    })
                    .catch(() => {
                        console.log('pushUserda xatolik')
                        reject()
                    })
            })
        },
        teacherUser(context, id) {
            return new Promise((resolve, reject) => {
                apiClient.put(`${SERVICE_ENDPOINT}/make_teacher`, {
                    user: id
                })
                    .then((response) => {
                        context.commit('updatePushUsers', response.data.user)
                        resolve()
                    })
                    .catch(() => {
                        console.log('pushUserda xatolik')
                        reject()
                    })
            })
        },
        fetchToken(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`${SERVICE_ENDPOINT}/auth`, data)
                    .then((response) => {
                        context.commit('updateToken', response.data)
                        resolve()
                    })

                    .catch(() => {
                        console.log('fetchTokenda xatolik yuzberdi')
                        reject()
                    })
            })
        },
        fetchRefreshToken(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`${SERVICE_ENDPOINT}/auth/refreshToken`, data)
                    .then((response) => {
                        context.commit('updateToken', response.data)
                        resolve()
                    })

                    .catch(() => {
                        context.commit('clearTokens')
                        console.log('fetchTokenda xatolik yuzberdi')
                        reject()
                    })
            })
        },
        clearTokens(ctx) {
            ctx.commit('clearTokens')
            ctx.commit('clearRoles')
        },
        fetchUser(context) {
            return new Promise((resolve, reject) => {
                if(context.state.user_me.id !== null){
                    resolve()

                    return
                }

                apiClient.get(`${SERVICE_ENDPOINT}/about_me`)
                    .then((res) => {
                        let user_me = {
                            id: res.data.id,
                            email: res.data.email,
                            givenName: res.data.givenName,
                            familyName: res.data.familyName,
                            phoneNumber: res.data.phoneNumber,
                            telegramNumber: res.data.telegramNumber,
                            roles: res.data.roles,
                            comments: res.data.comments,
                            isTeacher: res.data.isTeacher
                        }
                        context.commit('updateUser', user_me)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchUserda muammo bor ')
                        reject()
                    })
            })
        },
        fetchUsers(context, page) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?page=` + page + '&order[id]=desc')
                    .then((response) => {
                        let users = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateUsers', users)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchUserda muammo bor ')
                        reject()
                    })
            })
        },
        fetchRequestResetPassword(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`${SERVICE_ENDPOINT}/request_reset_password`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (error === 'connectionRefused') {
                            reject(error)
                        }
                        console.log(error.response)
                        reject(error.response.data['hydra:description'])
                    })
            })
        },
        fetchResetPassword(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`${SERVICE_ENDPOINT}/reset_password`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (error === 'connectionRefused') {
                            reject(error)
                        }
                        console.log(error.response)
                        reject(error.response.data['hydra:description'])
                    })
            })
        },
        fetchCheckResetToken(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`${SERVICE_ENDPOINT}/check_reset_token`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (error === 'connectionRefused') {
                            reject(error)
                        }
                        console.log(error.response)
                        reject(error.response.data['hydra:description'])
                    })
            })
        },
        fetchTeachers(context, page) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?page=` + page + '&isTeacher=true')
                    .then((response) => {
                        let users = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateTeachers', users)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchTeachersda muammo bor ')
                        reject()
                    })
            })
        },
        fetchOneUser(context, userId) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}/` + userId)
                    .then((response) => {
                        let oneUser = response.data

                        context.commit('updateOneUser', oneUser)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        deleteOneUser(context, userId) {
            return new Promise((resolve, reject) => {
                apiClient.delete(`${SERVICE_ENDPOINT}/` + userId)
                    .then((response) => {
                        console.log(response, 'bu res')
                        let oneUser = response.data

                        context.commit('updateOneUser', oneUser)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        searchUser(context, searchItem) {
            let url = "users?page=" + searchItem.page;

            if (searchItem.id) {
                url = url + '&id=' + searchItem.id
            }

            if (searchItem.givenName) {
                url = url + '&givenName=' + searchItem.givenName
            }

            if (searchItem.familyName) {
                url = url + '&familyName=' + searchItem.familyName
            }

            if (searchItem.email) {
                url = url + '&email=' + searchItem.email
            }

            if (searchItem.phoneNumber) {
                url = url + '&phoneNumber=' + searchItem.phoneNumber
            }

            if (searchItem.telegramNumber) {
                url = url + '&telegramNumber=' + searchItem.telegramNumber
            }

            if (searchItem.createdAt) {
                url = url + '&createdAt=' + searchItem.createdAt
            }

            if (searchItem.createdAt) {
                url = url + '&studentInfo=' + searchItem.studentInfo
            }

            return new Promise((resolve, reject) => {
                apiClient.get(url)
                    .then((response) => {
                        let data = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateSearchUser', data)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        searchTeacher(context, searchData) {
            let url = "users?page=" + searchData.page;

            if (searchData.id) {
                url = url + '&id=' + searchData.id + '&isTeacher=true'
            }

            if (searchData.givenName) {
                url = url + '&givenName=' + searchData.givenName + '&isTeacher=true'
            }

            if (searchData.familyName) {
                url = url + '&familyName=' + searchData.familyName + '&isTeacher=true'
            }

            if (searchData.email) {
                url = url + '&email=' + searchData.email + '&isTeacher=true'
            }

            if (searchData.phoneNumber) {
                url = url + '&phoneNumber=' + searchData.phoneNumber + '&isTeacher=true'
            }

            if (searchData.telegramNumber) {
                url = url + '&telegramNumber=' + searchData.telegramNumber + '&isTeacher=true'
            }

            return new Promise((resolve, reject) => {
                apiClient.get(url)
                    .then((response) => {
                        let data = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateSearchTeachers', data)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        searchUserByMatchingText(context, text) {
            return new Promise((resolve, reject) => {
                apiClient.post(`${SERVICE_ENDPOINT}/find_user`, text)
                    .then((response) => {
                        let foundUsers = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateFoundUser', foundUsers)
                        resolve()
                    })
                    .catch(() => {
                        console.log('pushUserda xatolik')
                        reject()
                    })
            })
        },
        fetchMyBoughtCoursesNew(context, userId) {
            return new Promise((resolve, reject) => {
                apiClient
                    .get(`${SERVICE_ENDPOINT}/` + userId + `/my_bought_courses`)
                    .then((response) => {
                        let myBoughtCourses = response.data.myCourses
                        context.commit('updateMyBoughtCourses', myBoughtCourses)
                        resolve()
                    })
                    .catch(() => {
                        console.log('get boughtCourses da xato')
                        reject()
                    })
            })
        },
        fetchMineBoughtCoursesNew(context, userId) {
            return new Promise((resolve, reject) => {
                apiClient
                    .get(`${SERVICE_ENDPOINT}/` + userId + `/my_bought_courses`)
                    .then((response) => {
                        let mineBoughtCourses = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateMineBoughtCourses', mineBoughtCourses)
                        resolve()
                    })
                    .catch(() => {
                        console.log('get boughtCourses da xato')
                        reject()
                    })
            })
        },
        fetchMyMiddleBoughtCourses(context, userId) {
            return new Promise((resolve, reject) => {
                apiClient
                    .get(`${SERVICE_ENDPOINT}?id=` + userId + `&boughtCourses.course.type.category.name=web-middle&boughtCourses.isPayed=true`)
                    .then((response) => {
                        let courses = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateMyMiddleCourses', courses)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchTakeCourse da xato')
                        reject()
                    })
            })
        },
        fetchMyCourses(context) {
            return new Promise((resolve, reject) => {
                apiClient
                    .get(`${SERVICE_ENDPOINT}/my_courses`)
                    .then((response) => {
                        let myCourses = response.data['hydra:member']

                        context.commit('updateMyCourses', myCourses)
                        resolve()
                    })
                    .catch(() => {
                        console.log('get myCourses da xato')
                        reject()
                    })
            })
        },
        putUserInfo(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.put(`${SERVICE_ENDPOINT}/${data.id}`, data)
                    .then((response) => {
                        context.commit('updateUser', response.data)
                        resolve()
                    })
                    .catch(() => {
                        console.log('put user da xato')
                        reject()
                    })
            })
        },
        putStudentInfo(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.put(`${SERVICE_ENDPOINT}/` + data.id + '/student_info', {studentInfo: data.studentInfo})
                    .then((response) => {
                        resolve(response)
                    })
                    .catch(() => {
                        console.log('put user da xato')
                        reject()
                    })
            })
        },
    },
    mutations: {
        updateOneUser(state, data) {
            state.oneUser = data
        },
        updateSearchUser(state, data) {
            state.usersList = data
        },
        updatePushUsers(state, data) {
            state.user = data
        },
        updateUsers(state, user) {
            state.usersList = user
        },
        updateTeachers(state, user) {
            state.teachersList = user
        },
        updateSearchTeachers(state, user) {
            state.teachersList = user
        },
        updateUser(state, user) {
            state.user_me = user
        },
        updateToken(state, tokens) {
            localStorage.setItem('accessToken', tokens.accessToken)
            localStorage.setItem('refreshToken', tokens.refreshToken)

            state.accessToken = tokens.accessToken
            state.refreshToken = tokens.refreshToken
        },
        updateFoundUser(state, foundUsers) {
            state.foundUser = foundUsers
        },
        updateMineBoughtCourses(state, mineBoughtCourses) {
            state.mineBoughtCourses = mineBoughtCourses
        },
        updateMyBoughtCourses(state, myBoughtCourses) {
            state.userBoughtCourses = myBoughtCourses
        },
        updateMyCourses(state, data) {
            state.myCourses = data
        },
        clearTokens(state) {
            localStorage.setItem('accessToken', null)
            localStorage.setItem('refreshToken', null)

            state.accessToken = null
            state.refreshToken = null
        }
    },
    state: {
        oneUser: {
            id: null,
            email: "",
            givenName: "",
            familyName: "",
            phoneNumber: "",
            telegramNumber: "",
        },
        user: {
            id: null,
            email: "",
            password: "",
            givenName: "",
            familyName: "",
            phoneNumber: "",
            telegramNumber: "",
            studentInfo: "",
        },
        foundUser: {
            models: [],
            totalItems: 0
        },
        usersList: {
            models: '',
            totalItems: 0
        },
        usersListGivenName: {
            models: '',
            totalItems: 0
        },
        usersListFamilyName: {
            models: '',
            totalItems: 0
        },
        usersListTelegramNumber: {
            models: '',
            totalItems: 0
        },
        usersListPhoneNumber: {
            models: '',
            totalItems: 0
        },
        teachersList: {
            models: [],
            totalItems: 0
        },
        user_me: {
            id: null,
            email: "",
            password: "",
            givenName: "",
            familyName: "",
            phoneNumber: "",
            telegramNumber: "",
            roles: [],
            comments: [],
            isTeacher: false
        },
        userBoughtCourses: {
            models: [],
            totalItems: 0
        },
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),
        myBoughtCourses: [],
        mineBoughtCourses: {
            models: [],
            totalItems: 0
        },
        myCourses: null
    },
    getters: {
        getUsersList(state) {
            return state.usersList.models
        },
        getTeachersList(state) {
            return state.teachersList.models
        },
        getTeachersTotalCount(state) {
            return state.teachersList.totalItems
        },
        getSearchTeachers(state) {
            return state.teachersList.models
        },
        getUsersTotalCount(state) {
            return state.usersList.totalItems
        },
        getOneUser(state) {
            return state.oneUser
        },
        getPushUser(state) {
            return state.user.data
        },
        getSearchUser(state) {
            return state.usersList.models
        },
        getFoundUser(state) {
            return state.foundUser.models
        },
        getUser(state) {
            return state.user_me.roles
        },
        getUserInfo(state) {
            return state.user_me
        },
        isTeacher(state) {
            return state.user_me.isTeacher
        },
        getToken(state) {
            return state.accessToken;
        },
        getRefreshToken(state) {
            return state.refreshToken
        },
        isAdmin(state, getters) {
            return getters.getUser.includes('ROLE_ADMIN');
        },
        getAccessToken(state) {
            return state.accessToken
        },
        getMineBoughtCoursesList(state) {
            return state.mineBoughtCourses.models
        },
        getMyBoughtCourses(state) {
            return state.myBoughtCourses
        },
        getUserBoughtCourses(state) {
            return state.userBoughtCourses.models
        },
        getMyCourses(state) {
            return state.myCourses
        },
        isAuthorized(state) {
            return state.accessToken !== null
        }
    }
}
