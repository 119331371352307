import apiClient from "@/plugins/vuex/axios";

const SERVICE_ENDPOINT = 'modules'

export default {
    actions: {
        fetchAddModule(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`${SERVICE_ENDPOINT}`, data)
                    .then((response) => {
                        context.commit('updateModule', response)
                        resolve()
                    })
                    .catch(() => {
                        console.log('post course da xato')
                        reject()
                    })
            })
        },
        putModule(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.put(`${SERVICE_ENDPOINT}/${data.id}`, data)
                    .then((response) => {
                        context.commit('updatePutModule', response.data)
                        resolve()
                    })
                    .catch(() => {
                        console.log('Post moduleda xato')
                        reject()
                    })
            })
        },
        fetchModules(context) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?&isArchive=false`)
                    .then((response) => {
                        let modules = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateGetModules', modules)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchModules da xato')
                        reject()
                    })
            })
        },
        fetchAllModules(context, page) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?page=` + page + `&order[id]=desc`)
                    .then((response) => {
                        let modules = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateGetAllModules', modules)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchModules da xato')
                        reject()
                    })
            })
        },
        fetchModule(context, moduleId) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}/` + moduleId)
                    .then((response) => {
                        let module = response.data

                        context.commit('updateGetModule', module)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchModule da xato')
                        reject()
                    })
            })
        },
        fetchModulesJunior(context) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?&isArchive=false&courses.type.category.name=web-junior`)
                    .then((response) => {
                        let modules = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateGetModules', modules)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchModulesJunior da xato')
                        reject()
                    })
            })
        },
        fetchModulesMiddle(context) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?&isArchive=false&courses.type.category.name=web-middle`)
                    .then((response) => {
                        let modules = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateGetModules', modules)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchModulesMiddle da xato')
                        reject()
                    })
            })
        },
        archiveTheCourse(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.put(`${SERVICE_ENDPOINT}/` + data.id, data)
                    .then((response) => {
                        context.commit('updateArchiveModule', response.data)
                        resolve()
                    })
                    .catch(() => {
                        console.log('Archive course da xato')
                        reject()
                    })
            })
        },
        deleteOneCourse(context, courseId) {
            return new Promise((resolve, reject) => {
                apiClient.delete(`${SERVICE_ENDPOINT}/` + courseId)
                    .then((response) => {
                        console.log(response)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
    },
    mutations: {
        updateModule(state, data) {
            state.addModule = data
        },
        updateArchiveModule(state, data) {
            state.archiveCourse = data
        },
        updateGetModules(state, data) {
            state.modules = data
        },
        updateGetAllModules(state, data) {
            state.modules = data
        },
        updateGetModule(state, data) {
            state.module = data
        },
        updatePutModule(state, addModule) {
            state.module = addModule
        },
    },
    state: {
        modules: {
            models: [],
            totalItems: 0
        },
        archiveCourse: {
            id: null,
            name: '',
            isArchive: null
        },
        module: {
            id: null,
            name: '',
            discountPercent: null,
            courses:[{
                id: null,
                countOfStudents: null,
                name: '',
                startMonth: {
                    id: null,
                    name: ''
                },
                isActive: null,
                price: null,
                type:{
                    id: null,
                    name: '',
                    category:{
                        id: null,
                        name: '',
                    }
                }
            }]
        },

    },
    getters: {
        getModules(state) {
            return state.modules.models
        },
        getAllModules(state) {
            return state.modules.models
        },
        getAllModulesTotal(state) {
            return state.modules.totalItems
        },
        getModule(state) {
            return state.module;
        },
        getArchiveModule(state) {
            return state.archiveCourse;
        },
    }
}
