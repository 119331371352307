<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="head">
                    <nav class="navbar navbar-expand-lg navbar-dark">
                        <div class="container-fluid overflow-hidden">
                            <router-link class="navbar-brand" to="/"><p class="str675">Kadirov.Dev</p></router-link>
                            <router-link class="navbar-brand zoom" :to="isAuthenticated ? '/cabinet' : '/registration'">
                                <p> kabinet <i class="fa fa-home"></i></p>
                            </router-link>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Header",
    computed: {
        ...mapGetters(['getToken', 'getAccessToken']),
        isAuthenticated() {
            return this.getAccessToken
        }
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');

* {
    transition: all 0.7s;
    animation: fadeInDown;
    animation-duration: 1s;
}

.head {
    /*max-width: 1110px;*/
    min-width: 310px;
    height: 70px;
    background-color: #80007F;
    font-family: 'Comfortaa', sans-serif;
    font-size: 24px;
    line-height: 27px;
    border-radius: 0 0 16px 16px;
}

.head p {
    margin: 8px 0;
}

.navbar-brand:hover {
    transform: scale(1.05);
    margin-left: 2px;
}

.head:hover {
    background-color: #990098 !important;
}

</style>
