import Vue from 'vue'
import App from './App.vue'
import store from "./plugins/vuex/store";
import router from "./plugins/router";
import { BootstrapVue} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueMask from 'v-mask'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import VueExcelXlsx from "vue-excel-xlsx";
import {Vuelidate} from "vuelidate";

Vue.use(VueExcelXlsx)

Vue.use(VueTelInput)

Vue.use(VueMask);

Vue
    .use(BootstrapVue)
    .use(Vuelidate)

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
