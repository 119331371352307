import apiClient from "@/plugins/vuex/axios";

const SERVICE_ENDPOINT = '/categories'

export default {
    actions: {
        fetchCategories(context) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}`)
                    .then((response) => {
                        let categories = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateCategories', categories)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchTakeCourse da xato')
                        reject()
                    })
            })
        },
        fetchCategory(context, categoryId) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}/` + categoryId)
                    .then((response) => {
                        let category = response.data

                        console.log(category)

                        context.commit('updateCategory', category)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchCategory da xato')
                        reject()
                    })
            })
        },
        pushCategory(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`${SERVICE_ENDPOINT}`, data)
                    .then((response) => {
                        context.commit('updateCategories', response.data.categories)
                        resolve()
                    })
                    .catch(() => {
                        console.log('post category da xato')
                        reject()
                    })
            })
        },
        putCategory(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.put(`${SERVICE_ENDPOINT}/${data.id}`, data)
                    .then((response) => {
                        context.commit('updateCategory', response.data)
                        resolve()
                    })
                    .catch(() => {
                        console.log('put category da xato')
                        reject()
                    })
            })
        },
        deleteCategory(context, categoryId) {
            return new Promise((resolve, reject) => {
                apiClient.delete(`${SERVICE_ENDPOINT}/` + categoryId)
                    .then((response) => {
                        console.log(response)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        fetchCategoryPagination(context, page) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?page=` + page + '&order[id]=desc')
                    .then((response) => {
                        let categories = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateCategories', categories)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchCourseTypes da xato')
                        reject()
                    })
            })
        },
        searchCategory(context, searchItem) {
            let url = "categories?page=" + searchItem.page;

            if (searchItem.id) {
                url = url + '&id=' + searchItem.id
            }

            if (searchItem.name) {
                url = url + '&name=' + searchItem.name
            }

            return new Promise((resolve, reject) => {
                apiClient.get(url)
                    .then((response) => {
                        let data = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateSearchCategory', data)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
    },
    mutations: {
        updateCategories(state, data) {
            state.categories = data
        },
        updateSearchCategory(state, data){
            state.categories = data
        },
        updateCategory(state, data){
            state.category = data
        }
    },
    state: {
        categories: {
            models: [],
            totalItems: 0,
        },
        category:{
            id: null,
            name: '',
        }
    },
    getters: {
        getCategories(state) {
            return state.categories.models
        },
        getCategory(state) {
            return state.category
        },
        getSearchCategory(state){
            return state.categories.models
        }
    }
}
