import store from "@/plugins/vuex/store";

export default {
    hasRoleAdmin() {
        return this.hasRole('ROLE_ADMIN')
    },
    hasRole(roleName) {
        return store.getters.getUser.includes(roleName)
    },
    hasTeacherUser() {
        return this.hasTeacher()
    },
    hasTeacher() {
        return store.getters.getUserInfo.isTeacher
    },
}
