<template>
    <div id="app">
        <div class="container-fluid">
            <Header/>
            <router-view/>
            <go-to-top-button/>
            <!--            <home-page/>-->
            <Footer/>
        </div>

    </div>
</template>

<script>


import Header from "./components/Header";
import Footer from "./components/Footer";
import {mapActions, mapGetters} from "vuex";
import GoToTopButton from "@/components/goToTopButton";

export default {
    name: 'App',
    components: {
        GoToTopButton,
        Footer,
        Header,
        // HomePage
    },
    computed: {
        ...mapGetters(['isAuthorized', 'getRefreshToken'])
    },
    methods: {
        ...mapActions(['fetchUser', 'fetchRefreshToken']),
        checkTokenActivity() {
            if (this.isAuthorized) {
                this.fetchUser()
                    .catch(() => {
                        this.tryToRefreshToken()
                    })
            }
        },
        tryToRefreshToken() {
            this.fetchRefreshToken({refreshToken: this.getRefreshToken})
                .catch(() => {
                    this.$router.push('/login')
                })
        }
    },
    mounted() {
        this.checkTokenActivity()
    }
}
</script>

<style>
* {
    font-family: Comfortaa, cursive;
}

.container-fluid {
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden;
}

/* Edit style scroll start */
::-webkit-scrollbar {
    width: 10px;
    background-color: #f2f2f2;
}

::-webkit-scrollbar-thumb {
    background-color: #80007F;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #80007F;
}

::-webkit-scrollbar-track {
    background-color: #f2f2f2;
}
/* Edit style scroll end */

.page-item.active .page-link {
    background-color: red !important;
    border-color: red !important;
    color: #FFFFFF !important;
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #80007F !important;
    border-color: #80007F !important;
}
.page-link {
    color: #80007F !important;
}

.hover-decoration {
    text-decoration: none !important;
    display: inline-block !important;
    position: relative !important;
    color: #0D6EFD !important;
    cursor: pointer;
}

.hover-decoration:after {
    content: '' !important;
    position: absolute !important;
    width: 100% !important;
    transform: scaleX(0) !important;
    height: 2px !important;
    bottom: 0 !important;
    left: 0 !important;
    background-color: #0D6EFD !important;
    transform-origin: bottom center !important;
    transition: transform 0.15s ease-out !important;
}

.hover-decoration:hover:after {
    transform: scaleX(1) !important;
    transform-origin: bottom center !important;
}

.justify-content-left .btn-primary {
    text-align: center;
    padding: 5px 60px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
}

.btn-primary {
    background-color: #80007F !important;
    border-color: #80007F;
    outline: none !important;
    color: #fff;
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: none !important;
}

.btn-primary:hover {
    background-color: #990098 !important;
    border-color: #990098 !important;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}
</style>
