<template>
    <div
        @click="scrollToTop()"
        :class="['back-to-top-btn', {'go-top': isTop}]"
    >
        <p class="top-arrow">&#9650;</p>
    </div>
</template>

<script>
export default {
    name: "goToTopButton",
    data() {
        return {
            isTop: false
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    },
    mounted() {
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            that.isTop = scrollPos >= 100;
        })
    }
}
</script>

<style scoped>

.back-to-top-btn {
    position: fixed;
    cursor: pointer;
    left: 48px;
    border: 2px #80007F solid;
    background-color: #ffffff;
    z-index: 3 !important;
    width: 55px;
    height: 55px;
    visibility: hidden;
    border-radius: 50%;
}

.top-arrow {
    position: absolute;
    right: 0;
    left: 0;
    top: 45%;
    transform: translateY(-45%);
    text-align: center;
    font-size: 30px;
    color: #80007F;
}

.go-top {
    visibility: visible;
    bottom: 40px;
}

.top-arrow:hover {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px #FFFFFF solid;
    background-color: #80007F;
    color: #ffffff;
}
</style>
