import apiClient from "@/plugins/vuex/axios";

const SERVICE_ENDPOINT = 'promo_codes'

export default {
    actions: {
        createPromoCode(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`${SERVICE_ENDPOINT}`, data)
                    .then((response) => {
                        context.commit('updatePromoCode', response)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        checkPromoCode(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`${SERVICE_ENDPOINT}/is_unique_promo`, data)
                    .then((response) => {
                        context.commit('updateCheckPromoCode', response.data)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        findPromoCode(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`${SERVICE_ENDPOINT}/find`, data)
                    .then((response) => {
                        context.commit('updateFoundPromoCode', response.data)
                        resolve()
                    })
                    .catch((error) => {
                        reject(error.response.data['hydra:description'])
                    })
            })
        },
        fetchPromoCodes(context, page) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?itemsPerPage=20&order[id]=desc&page=` + page)
                    .then((response) => {
                        let promoCodes = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updatePromoCodes', promoCodes)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        fetchPromoCode(context, promoId) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}/` + promoId)
                    .then((response) => {
                        let promoCode = response.data

                        context.commit('updatePromo', promoCode)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        deletePromoCode(context, promoId) {
            return new Promise((resolve, reject) => {
                apiClient.delete(`${SERVICE_ENDPOINT}/` + promoId)
                    .then((response) => {
                        console.log(response)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        putPromoCode(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.put(`${SERVICE_ENDPOINT}/${data.id}`, data)
                    .then((response) => {
                        context.commit('updatePromoCode', response.data)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        searchPromo(context, searchItem) {
            let url = "promo_codes?page=" + searchItem.page;

            if (searchItem.promo) {
                url = url + '&promo=' + searchItem.promo
            }

            if (searchItem.discount) {
                url = url + '&discount=' + searchItem.discount
            }

            if (searchItem.isPercent) {
                url = url + '&isPercent=' + searchItem.isPercent
            }

            if (searchItem.amount) {
                url = url + '&amount=' + searchItem.amount
            }

            if (searchItem.until) {
                url = url + '&until=' + searchItem.until
            }

            if (searchItem.courseType) {
                url = url + '&courseType.name=' + searchItem.courseType
            }

            return new Promise((resolve, reject) => {
                apiClient.get(url)
                    .then((response) => {
                        let data = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateSearchPromo', data)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
    },
    mutations: {
        updatePromoCode(state, payload) {
            state.addPromo = payload
        },
        updatePromo(state, payload) {
            state.promoCode = payload
        },
        updatePromoCodes(state, payload) {
            state.promoCodes = payload
        },
        updateCheckPromoCode(state, payload) {
            state.checkPromo = payload
        },
        updateFoundPromoCode(state, payload) {
            state.promo = payload
        },
        updateSearchPromo(state, data) {
            state.promoCodes = data
        },
    },
    state: {
        addPromo: {
            promo: '',
            discount: 0,
            until: '',
            isPercent: false,
            amount: 0,
            promos: []
        },
        checkPromo: {
            isUnique: false
        },
        promo: null,
        promoCodes: {
            models: '',
            totalItems: 0
        },
        promoCode: null,
    },
    getters: {
        getSearchPromo(state) {
            return state.promoCodes.models
        },
        getPromo(state) {
            return state.addPromo;
        },
        getCheckPromo(state) {
            return state.checkPromo;
        },
        getFoundPromo(state) {
            return state.promo
        },
        getPromoCodes(state) {
            return state.promoCodes.models
        },
        getPromoCodesTotal(state) {
            return state.promoCodes.totalItems
        },
        getPromoCode(state) {
            return state.promoCode
        }
    }
}
