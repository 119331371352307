import apiClient from "@/plugins/vuex/axios";

const SERVICE_ENDPOINT = 'months'

export default {
    actions: {
        fetchMonth(context) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?pagination=false`)
                    .then((response) => {
                        let months = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateMonths', months)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetch month da xato')
                        reject()
                    })
            })
        },
    },
    mutations: {
        updateMonths(state, months) {
            state.month = months
        },
    },
    state: {
        month: {
            models: [],
            totalItems: 0
        },

    },
    getters: {
        getMonths(state) {
            return state.month;
        },
    }
}