import apiClient from "@/plugins/vuex/axios";

const SERVICE_ENDPOINT = 'comments'

export default {
    actions: {
        pushComment(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`${SERVICE_ENDPOINT}`, data)
                    .then((response) => {
                        context.commit('updateComment', response)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchComment da xato')
                        reject()
                    })
            })
        },
        fetchComments(context, page) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?itemsPerPage=6&page=` + page + '&pagination=true&isApprove=true&order[id]=desc')
                    .then((response) => {
                        let comments = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        console.log(comments)
                        context.commit('updateComments', comments)
                        resolve()
                    })
                    .catch(() => {
                        console.log('get comments da xato')
                        reject()
                    })
            })
        },
        fetchAdminComments(context, page) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?itemsPerPage=20&order[id]=desc&page=` + page)
                    .then((response) => {
                        let comments = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        console.log(comments, 'allComments')
                        context.commit('updateAllComments', comments)
                        resolve()
                    })
                    .catch(() => {
                        console.log('get comments da xato')
                        reject()
                    })
            })
        },
        fetchUserComments(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?page=` + data.page + `&itemsPerPage=20&user.id=` + data.userId)
                    .then((response) => {
                        let comments = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        console.log(comments, 'allComments')
                        context.commit('updateAllComments', comments)
                        resolve()
                    })
                    .catch(() => {
                        console.log('get comments da xato')
                        reject()
                    })
            })
        },
        fetchAllComments(context) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?pagination=false&order[id]=desc`)
                    .then((response) => {
                        console.log(response, 'commentlar')
                        let comments = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItem']
                        }
                        console.log(comments)
                        context.commit('updateAllComments', comments)
                        resolve()
                    })
                    .catch(() => {
                        console.log('get comments da xato')
                        reject()
                    })
            })
        },
        fetchAllNewComments(context) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?pagination=false&hasSeen=false`)
                    .then((response) => {
                        let comments = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        console.log(comments)
                        context.commit('updateAllNewComments', comments)
                        resolve()
                    })
                    .catch(() => {
                        console.log('get comments da xato')
                        reject()
                    })
            })
        },
        makeHasSeen() {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}/has_seen`)
                    .then((response) => {
                        console.log(response)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchApprove da xato')
                        reject()
                    })
            })
        },
        putIsApprove(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.put(`${SERVICE_ENDPOINT}/${data.id}/is_approve`, {
                    id: data.id,
                    isApprove: data.isApprove,
                })
                    .then((response) => {
                        context.commit('updateIsApprove', response)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchApprove da xato')
                        reject()
                    })
            })
        },
        putComment(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.put(`${SERVICE_ENDPOINT}/` + data.id, data)
                    .then((response) => {
                        context.commit('updateMyComment', response)
                        resolve()
                    })
                    .catch(() => {
                        console.log('put comment da xato')
                        reject()
                    })
            })
        },
        deleteComment(context, id) {
            return new Promise((resolve, reject) => {
                apiClient.delete(`${SERVICE_ENDPOINT}/` + id)
                    .then((response) => {
                        context.commit('updateAllComments', response)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchComment da xato')
                        reject()
                    })
            })
        },
        fetchMyComment(context, commentId) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}/` + commentId)
                    .then((response) => {
                        let promoCode = response.data

                        context.commit('updateComment', promoCode)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        searchCommentsInfo(context, searchItem) {
            let url = "comments?page=" + searchItem.page;

            if (searchItem.id) {
                url = url + '&user.id=' + searchItem.id
            }

            if (searchItem.givenName) {
                url = url + '&user.givenName=' + searchItem.givenName
            }

            if (searchItem.familyName) {
                url = url + '&user.familyName=' + searchItem.familyName
            }

            if (searchItem.isApprove !== null) {
                url = url + '&isApprove=' + searchItem.isApprove
            }

            return new Promise((resolve, reject) => {
                apiClient.get(url)
                    .then((response) => {
                        let data = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateSearchAllComments', data)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
    },
    mutations: {
        updateComment(state, data) {
            state.addComment = data
        },
        updateMyComment(state, data) {
            state.putComment = data
        },
        updateComments(state, data) {
            state.comments = data
        },
        updateAllComments(state, data) {
            state.allComments = data
        },
        updateSearchAllComments(state, data) {
            state.allComments = data
        },
        updateAllNewComments(state, data) {
            state.allNewComments = data
        },
        updateIsApprove(state, data) {
            state.addComment = data
        },
    },
    state: {
        addComment: {
            course: '',
            image: '',
            text: '',
        },
        putComment: {
            text: '',
            image: '',
            course: ''
        },
        comments: {
            models: [],
            totalItems: 0
        },
        allComments: {
            models: [],
            totalItems: 0
        },
        allNewComments: {
            models: [],
            totalItems: 0
        },
    },
    getters: {
        getComment(state) {
            return state.addComment
        },
        getComments(state) {
            return state.comments.models
        },
        getCommentsTotalCount(state) {
            return state.comments.totalItems
        },
        getAllComments(state) {
            return state.allComments.models
        },
        getAllCommentsTotal(state) {
            return state.allComments.totalItems
        },
        newCommentsCount(state) {
            return state.allNewComments.totalItems
        }
    },
}