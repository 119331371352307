import apiClient from "@/plugins/vuex/axios";

const SERVICE_ENDPOINT = 'course_students'

export default {
    actions: {
        fetchCourseStudents(context, course) {
            return apiClient.get(`${SERVICE_ENDPOINT}?pagination=false&course=` + course)
                .then((response) => {
                    let course = {
                        models: response.data['hydra:member'],
                        totalItems: response.data['hydra:totalItems']
                    }
                    console.log(course)
                    context.commit('updateTakeCourse', course)
                })
                .catch(() => {
                    console.log('searchCourse da xato')
                })
        },
        fetchStudentCourses(context, userId) {
            return apiClient.get(`${SERVICE_ENDPOINT}?user.id=${userId}`)
                .then((response) => {
                    console.log(response)
                    let coursesUser = {
                        models: response.data['hydra:member'],
                        totalItems: response.data['hydra:totalItems']
                    }
                    context.commit('updateStudentCourses', coursesUser)
                })
                .catch(() => {
                    console.log('searchCourse da xato')
                })
        },
        fetchUserCourses(context, userId) {
            return apiClient.get(`${SERVICE_ENDPOINT}/students_courses?user.id=${userId}`)
                .then((response) => {
                    console.log(response)
                    let coursesStudent = {
                        models: response.data['hydra:member'],
                        totalItems: response.data['hydra:totalItems']
                    }
                    context.commit('updateUserCourses', coursesStudent)
                })
                .catch(() => {
                    console.log('searchCourse da xato')
                })
        },
        deleteStudent(context, studentId) {
            return new Promise((resolve, reject) => {
                apiClient.delete(`${SERVICE_ENDPOINT}/` + studentId)
                    .then((response) => {
                        console.log(response)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        searchCourseStudentInfo(context, searchItem) {
            let url = "course_students?page=" + searchItem.page;

            if (searchItem.id) {
                url = url + '&user.id=' + searchItem.id
            }

            if (searchItem.course) {
                url = url + '&course=' + searchItem.course
            }

            if (searchItem.givenName) {
                url = url + '&user.givenName=' + searchItem.givenName
            }

            if (searchItem.familyName) {
                url = url + '&user.familyName=' + searchItem.familyName
            }

            if (searchItem.email) {
                url = url + '&user.email=' + searchItem.email
            }

            if (searchItem.phoneNumber) {
                url = url + '&user.phoneNumber=' + searchItem.phoneNumber
            }

            if (searchItem.telegramNumber) {
                url = url + '&user.telegramNumber=' + searchItem.telegramNumber
            }

            return new Promise((resolve, reject) => {
                apiClient.get(url)
                    .then((response) => {
                        let data = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateSearchCourseStudent', data)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
    },
    mutations: {
        updateTakeCourse(state, course) {
            state.course = course
        },
        updateStudentCourses(state, coursesUser) {
            state.userCourses = coursesUser
        },
        updateUserCourses(state, coursesStudent) {
            state.userCourses = coursesStudent
        },
        updateSearchCourseStudent(state, data) {
            state.course = data
        },
    },
    state: {
        course: {
            models: [],
            totalItems: 0
        },
        userCourses: {
            models: [],
            totalItems: 0
        },
    },
    getters: {
        getCourseStudents(state) {
            return state.course.models
        },
        getStudentCourses(state) {
            return state.userCourses.models
        },
        getUserCourses(state) {
            return state.userCourses.models
        },
    }
}
