import apiClient from "@/plugins/vuex/axios";

const SERVICE_ENDPOINT = 'bought_courses'

export default {
    actions: {
        fetchBoughtCourse(context, data) {
            return new Promise((resolve, reject) => {
                apiClient
                    .post(`${SERVICE_ENDPOINT}`, data)
                    .then((response) => {
                        console.log(response.data, 'data')
                        let boughtCourse = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateBoughtCourse', boughtCourse)
                        resolve()
                    })
                    .catch((error) => {
                        if (error === 'connectionRefused') {
                            reject(error)
                        }

                        console.log(error.response)
                        reject(error.response.data['hydra:description'])
                    })
            })
        },
        fetchBoughtCourses(context) {
            return new Promise((resolve, reject) => {
                apiClient
                    .get(`${SERVICE_ENDPOINT}`)
                    .then((response) => {
                        let boughtCourses = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateBoughtCourses', boughtCourses)
                        resolve()
                    })
                    .catch(() => {
                        console.log('get boughtCourses da xato')
                        reject()
                    })
            })
        },
        fetchMyBoughtCourses(context, userId) {
            return new Promise((resolve, reject) => {
                apiClient
                    .get(`${SERVICE_ENDPOINT}?pagination=false&user=` + userId + `&isPayed=true`)
                    .then((response) => {
                        let myBoughtCourses = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateMineBoughtCourses', myBoughtCourses)
                        resolve()
                    })
                    .catch(() => {
                        console.log('get boughtCourses da xato')
                        reject()
                    })
            })
        },
        fetchPromoCodeUsed(context, promo) {
            return new Promise((resolve, reject) => {
                apiClient
                    .get(`${SERVICE_ENDPOINT}?promo.id=` + promo)
                    .then((response) => {
                        let myBoughtCourses = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updatePromoCodeUsed', myBoughtCourses)
                        resolve()
                    })
                    .catch(() => {
                        console.log('get Promoni olishta xatolik  da xato')
                        reject()
                    })
            })
        },
        searchPromoCodeCount: function (context, searchItem) {
            let url = "bought_courses?page=" + searchItem.page;

            if (searchItem.promoId) {
                url = url + '&promo.id=' + searchItem.promoId
            }
            if (searchItem.courseName) {
                url = url + '&course.name=' + searchItem.courseName
            }

            if (searchItem.givenName) {
                url = url + '&user.givenName=' + searchItem.givenName
            }

            if (searchItem.familyName) {
                url = url + '&user.familyName=' + searchItem.familyName
            }

            return new Promise((resolve, reject) => {
                apiClient.get(url)
                    .then((response) => {
                        let data = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateSearchPromoCodeCunts', data)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
    },
    mutations: {
        updateBoughtCourse(state, boughtCourse) {
            state.boughtCourse = boughtCourse
        },
        updateBoughtCourses(state, data) {
            state.boughtCourses = data
        },
        updateMineBoughtCourses(state, data) {
            state.myBoughtCourses = data
        },
        updatePromoCodeUsed(state, data) {
            state.usePromoCode = data
        },
        updateSearchPromoCodeCunts(state, data) {
            state.usePromoCode = data
        },
    },
    state: {
        myBoughtCourses: {
            models: [],
            totalItems: 0,
        },
        usePromoCode: {
            models: [],
            totalItems: 0,
        },
        boughtCourse: {
            models: [],
            totalItems: 0,
        },
        boughtCourses: {
            models: [],
            totalItems: 0,
        },
    },
    getters: {
        getBoughtCourse(state) {
            return state.boughtCourse.models
        },
        getBoughtCourses(state) {
            return state.boughtCourses.models
        },
        getPromoCodeUsed(state) {
            return state.usePromoCode.models
        },
        getPromoCodeUsedTotalItems(state) {
            return state.usePromoCode.totalItems
        },
        getMineBoughtCourses(state) {
            return state.myBoughtCourses.models
        },
        getSearchPromoCodeUse(state) {
            return state.usePromoCode.models
        },
    }
}