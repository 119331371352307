import apiClient from "@/plugins/vuex/axios";

const SERVICE_ENDPOINT = 'courses'

export default {
    actions: {
        fetchAddCourse(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`${SERVICE_ENDPOINT}`, data)
                    .then((response) => {
                        context.commit('updateAddCourse', response.data.addCourse)
                        resolve()
                    })
                    .catch(() => {
                        console.log('post course da xato')
                        reject()
                    })
            })
        },
        putCourse(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.put(`${SERVICE_ENDPOINT}/${data.id}`, data)
                    .then((response) => {
                        context.commit('updateAddCourse', response.data.addCourse)
                        resolve()
                    })
                    .catch(() => {
                        console.log('post course da xato')
                        reject()
                    })
            })
        },
        addStudentToCourse(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`${SERVICE_ENDPOINT}/add_student`, data)
                    .then((response) => {
                        context.commit('updateAddStudentCourse', response.data.addCourse)
                        resolve()
                    })
                    .catch((error) => {
                        if (error === 'connectionRefused') {
                            reject(error)
                        }

                        console.log(error.response)
                        reject(error.response.data['hydra:description'])
                    })
            })
        },
        deleteStudentFromCourse(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`${SERVICE_ENDPOINT}/delete_student`, data)
                    .then((response) => {
                        context.commit('updateAddStudentCourse', response.data.addCourse)
                        resolve()
                    })
                    .catch((error) => {
                        if (error === 'connectionRefused') {
                            reject(error)
                        }

                        console.log(error.response)
                        reject(error.response.data['hydra:description'])
                    })
            })
        },
        fetchNotPaidCourseStudents(context, courseId) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}/` + courseId + `/not_paid_students`)
                    .then((response) => {
                        context.commit('updateNotPaidCoursesStudents', response.data)
                        resolve()
                    })
                    .catch((error) => {
                        if (error === 'connectionRefused') {
                            reject(error)
                        }

                        console.log(error.response)
                        reject(error.response.data['hydra:description'])
                    })
            })
        },
        fetchNotPaidCourseTeacherStudents(context, courseId) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}/` + courseId + `/not_paid_teacher_students`)
                    .then((response) => {
                        context.commit('updateNotPaidCoursesStudents', response.data)
                        resolve()
                    })
                    .catch((error) => {
                        if (error === 'connectionRefused') {
                            reject(error)
                        }

                        console.log(error.response)
                        reject(error.response.data['hydra:description'])
                    })
            })
        },
        deleteCourse(context, courseId) {
            return new Promise((resolve, reject) => {
                apiClient.delete(`${SERVICE_ENDPOINT}/` + courseId)
                    .then((response) => {
                        console.log(response)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                        console.log('deleteCourse\'da xatolik bor')
                    })
            })
        },
        fetchCourse(context, courseId) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}/` + courseId)
                    .then((response) => {
                        let course = response.data

                        context.commit('updateOneCourses', course)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchCourseda xato')
                        reject()
                    })
            })
        },
        fetchTakeCourse(context) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?pagination=false`)
                    .then((response) => {
                        let courses = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateTakeCourses', courses)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchTakeCourse da xato')
                        reject()
                    })
            })
        },
        fetchTakeCoursesJunior(context) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?&type.category.name=web-junior`)
                    .then((response) => {
                        let courses = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateTakeCourses', courses)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchTakeCourse da xato')
                        reject()
                    })
            })
        },
        fetchTakeCoursesMiddle(context) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?&type.category.name=web-middle`)
                    .then((response) => {
                        let courses = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateTakeMiddleCourses', courses)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchTakeCourse da xato')
                        reject()
                    })
            })
        },
        fetchTakeCourses(context, page) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}?page=` + page + '&itemsPerPage=20&order[id]=desc')
                    .then((response) => {
                        let courses = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateTakeCourses', courses)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchTakeCourse da xato')
                        reject()
                    })
            })
        },
        fetchActiveCourses(context) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}/get_courses?page=1&isActive=true`)
                    .then((response) => {
                        let courses = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateActiveCourses', courses)
                        resolve()
                    })
                    .catch(() => {
                        console.log('fetchTakeCourse da xato')
                        reject()
                    })
            })
        },
        searchOneCourse(context, course) {
            return apiClient.get(`${SERVICE_ENDPOINT}/` + course)
                .then((response) => {
                    let course = {
                        id: response.data.id,
                        type: response.data.type,
                        startMonth: response.data.startMonth,
                        countOfStudents: response.data.countOfStudents,
                        user: response.data.user,
                        members: response.data.members,
                        name: response.data.name,
                        isActive: response.data.isActive,
                        price: response.data.price,
                        link: response.data.link,
                        teacher: response.data.teacher,
                    }
                    context.commit('updateOneCourse', course)
                })
                .catch(() => {
                    console.log('fetchTakeCourse da xato')
                })

        },
        searchCourse(context, searchItem) {
            return apiClient.get(`${SERVICE_ENDPOINT}/` + searchItem.course)
                .then((response) => {
                    let course = {
                        id: response.data.id,
                        boughtCourses: response.data.boughtCourses
                    }
                    context.commit('updateTakeCourse', course)
                })
                .catch(() => {
                    console.log('searchCourse da xato')
                })
        },
        stopCourseAdmission(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.put(`${SERVICE_ENDPOINT}/` + data.id, data)
                    .then((response) => {
                        context.commit('updateAddCourse', response.data.addCourse)
                        resolve()
                    })
                    .catch(() => {
                        console.log('post course da xato')
                        reject()
                    })
            })
        },
        searchCourseInfo(context, searchItem) {
            let url = "courses?itemsPerPage=20&order[id]=desc&page=" + searchItem.page;

            if (searchItem.id) {
                url = url + '&id=' + searchItem.id
            }

            if (searchItem.name) {
                url = url + '&name=' + searchItem.name
            }

            if (searchItem.givenName) {
                url = url + '&teacher.givenName=' + searchItem.givenName
            }

            if (searchItem.familyName) {
                url = url + '&teacher.familyName=' + searchItem.familyName
            }

            if (searchItem.isActive !== null) {
                url = url + '&isActive=' + searchItem.isActive
            }

            if (searchItem.link) {
                url = url + '&link=' + searchItem.link
            }

            if (searchItem.startMonth) {
                url = url + '&startMonth=' + searchItem.startMonth
            }

            return new Promise((resolve, reject) => {
                apiClient.get(url)
                    .then((response) => {
                        let data = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }
                        context.commit('updateSearchCourse', data)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        findOneCourseJunior(context, moduleId) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}/get_courses?type.category.name=web-junior&module=` + moduleId)
                    .then((response) => {
                        let data = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }

                        context.commit('updateTakeJuniorCourses', data)
                        resolve()
                    })
                    .catch(() => {
                        console.log('searchCourse da xato')
                        reject()
                    })
            })
        },
        findOneCourseMiddle(context, moduleId) {
            return new Promise((resolve, reject) => {
                apiClient.get(`${SERVICE_ENDPOINT}/get_courses?type.category.name=web-middle&module=` + moduleId)
                    .then((response) => {
                        let data = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems']
                        }

                        context.commit('updateFindCourse', data)
                        resolve()
                    })
                    .catch(() => {
                        console.log('searchCourse da xato')
                        reject()
                    })
            })
        }
    },
    mutations: {
        updateNotPaidCoursesStudents(state, data) {
            state.notPaidCoursesStudents.models = data
        },
        updateAddCourse(state, addCourse) {
            state.addCourse = addCourse
        },
        updateFindCourse(state, course) {
            state.findCourses = course
        },
        updateActiveCourses(state, data) {
            state.activeCourses = data
        },
        updateTakeCourses(state, data) {
            state.courses = data
        },
        updateOneCourses(state, data) {
            state.course = data
        },
        updateTakeMiddleCourses(state, data) {
            state.middleCourses = data
        },
        updateTakeJuniorCourses(state, data) {
            state.juniorCourses = data
        },
        updateMyMiddleCourses(state, data) {
            state.myMiddleCourses = data
        },
        updateSearchCourse(state, data) {
            state.courses = data
        },
        updateTakeCourse(state, courseId) {
            state.course = courseId
        },
        updateOneCourse(state, course) {
            state.oneCourse = course
        },
        updateAddStudentCourse(state, addCourse) {
            state.addCourse = addCourse
        }
    },
    state: {
        notPaidCoursesStudents: {
            models: []
        },
        addCourse: {
            type: '',
            startMonth: '',
            countOfStudents: '',
        },
        activeCourses: {
            models: [],
            totalItems: 0
        },
        findCourses: {
            models: [],
            totalItems: 0
        },
        courses: {
            models: [],
            totalItems: 0
        },
        juniorCourses: {
            models: [],
            totalItems: 0
        },
        middleCourses: {
            models: [],
            totalItems: 0
        },
        myMiddleCourses: {
            models: [],
            totalItems: 0
        },
        course: {
            id: null,
            type: null,
            startMonth: null,
            boughtCourses: [],
            name: "",
            price: 0,
            teacher: null,
            link: "",
            module: null
        },
        oneCourse: {
            id: null,
            countOfStudents: null,
            members: null,
        }

    },
    getters: {
        getNotPaidCoursesStudents(state) {
            return state.notPaidCoursesStudents.models
        },
        getNotPaidTeacherStudents(state) {
            return state.notPaidCoursesStudents.models
        },
        getCourse(state) {
            return state.course;
        },
        getOneCourse(state) {
            return state.oneCourse;
        },
        getTakeCourses(state) {
            return state.courses.models
        },
        getFindCourses(state) {
            return state.findCourses.models
        },
        getTakeJuniorCourses(state) {
            return state.juniorCourses.models
        },
        getTakeMiddleCourses(state) {
            return state.middleCourses.models
        },
        getActiveCourses(state) {
            return state.activeCourses.models
        },
        getTakeCoursesTotal(state) {
            return state.courses.totalItems
        },
        getCourseById: (state, getters) => (id) => {
            return getters.getTakeCourses.find(model => model.id === id)
        },
        getMyMiddleCourses(state) {
            return state.myMiddleCourses.models
        }
    }
}
