import apiClient from "@/plugins/vuex/axios";

const SERVICE_ENDPOINT = 'comment_likes'

export default {
    actions: {
        pushCommentLike(context, data) {
            return new Promise((resolve, reject) => {
                apiClient.post(`${SERVICE_ENDPOINT}`, data)
                    .then((response) => {
                        context.commit('updateCommentLike', response)
                        resolve()
                    })
                    .catch((error) => {
                        console.log('post commentLike da xato')
                        reject(error)
                    })
            })
        }
    },
    mutations: {
        updateCommentLike(state, data) {
            state.addCommentLike = data
        }
    },
    state: {
        addCommentLike: {
            comment: ''
        }
    },
    getters: {
        getCommentLike(state) {
            return state.addCommentLike
        }
    }
}