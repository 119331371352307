const ACCESS_NAME = 'accessToken'
const REFRESH_NAME = 'refreshToken'

export default {
    getAccess() {
        return localStorage.getItem(ACCESS_NAME) || ''
    },
    setAccess(token) {
        localStorage.setItem(ACCESS_NAME, token)
    },
    getRefresh() {
        return localStorage.getItem(REFRESH_NAME) || ''
    },
    setRefresh(token) {
        localStorage.setItem(REFRESH_NAME, token)
    },
    clear() {
        this.setAccess('')
        this.setRefresh('')
    },
    clearToken() {
        localStorage.removeItem(ACCESS_NAME)
        localStorage.removeItem(REFRESH_NAME)
    }
}
